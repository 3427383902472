/* global axios */
// import ApiClient from './ApiClient';
import CacheEnabledApiClient from '../CacheEnabledApiClient';

export class StandbiesAPI extends CacheEnabledApiClient {
  constructor() {
    super('teams', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'standby';
  }

  // eslint-disable-next-line class-methods-use-this
  extractDataFromResponse(response) {
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  marshallData(dataToParse) {
    return { data: dataToParse };
  }

  get(teamId) {
    return axios.get(`${this.url}/${teamId}/distributions/standbies`);
  }

  /**
   * @typedef {Object} Standby
   * @property {string} start_at
   * @property {string} end_at
   * @property {string[]} team_members_ids
   */

  /**
   * @param {string} teamId
   * @param {Standby} data
   */
  create(teamId, data) {
    return axios.post(`${this.url}/${teamId}/distributions/standbies`, data);
  }

  /**
   * @param {string} teamId
   * @param {string} standbyId
   * @param {Standby} data
   */
  edit(teamId, standbyId, data) {
    return axios.put(
      `${this.url}/${teamId}/distributions/standbies/${standbyId}`,
      data
    );
  }

  cancel(teamId, standbyId) {
    return axios.put(
      `${this.url}/${teamId}/distributions/standbies/${standbyId}/cancel`
    );
  }
}

export default new StandbiesAPI();
